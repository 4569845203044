import { HelpMenuItem } from 'rio-core';

export const helpMenuItems: HelpMenuItem[] = [
  {
    id: 'manual',
    link: {
      en: 'https://learn.textengine.io/rewrite',
      de: 'https://learn.textengine.io/rewrite',
    },
    label: 'HelpMenu.Manual',
  },
  {
    id: 'api',
    link: {
      en: 'https://learn.textengine.io/help/de-de/59-rewrite/682-api-dokumentation-en',
      de: 'https://learn.textengine.io/help/de-de/59-rewrite/682-api-dokumentation-en',
    },
    label: 'HelpMenu.API Documentation',
  },
];
