import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Organization } from 'rio-core';
import { IconRegistryModule } from 'rio-ui';
import { FilterOrganizationsPipe } from './filter-organizations.pipe';

@Component({
  selector: 'app-select-organization',
  imports: [
    MatSelectModule,
    MatIconModule,
    IconRegistryModule,
    TranslateModule,
    MatTooltipModule,
    MatInputModule,
    FilterOrganizationsPipe,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './select-organization.component.html',
  styleUrl: './select-organization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOrganizationComponent implements OnChanges {
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
  @ViewChild('select') select: MatSelect;

  @Input({ required: true }) organizations: Organization[] = [];
  @Input({ required: true }) selectedOrganizationId = '';
  @Input({ required: true }) allowAddingOrganization: boolean;

  @Output() organizationSelected = new EventEmitter<string>();
  @Output() addOrganization = new EventEmitter<void>();

  // We use full organization as select value so we can access the selected organization's name in placeholder
  selectedOrganization: Organization;
  filterValue = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizations || changes.selectedOrganizationId) {
      this.selectedOrganization = this.organizations.find(
        org => org.id === this.selectedOrganizationId,
      );
    }
  }

  onOrganizationSelected(organization: Organization) {
    this.organizationSelected.emit(organization?.id);
    this.select.close();
    this.clearFilter();
  }

  public clearFilter() {
    this.filterValue = '';
  }

  public focusFilter() {
    this.filterInput.nativeElement.focus();
  }

  onAddOrganization() {
    this.addOrganization.emit();
    this.select.close();
  }
}
