import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { Store } from '@ngrx/store';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LocalStorageStoreModule } from 'local-storage';
import { CategoriesStoreModule } from 'rio-categories-data-access';
import {
  API_PREFIX,
  ConfigService,
  OrganizationsService,
  OrganizationsStoreModule,
  RouterStoreModule,
  SystemStoreModule,
  UserService,
  UserStoreModule,
  configProvider,
} from 'rio-core';
import { PromptChainsStoreModule } from 'rio-prompt-chains-data-access';
import { PromptsStoreModule } from 'rio-prompts-data-access';
import { ToastStoreModule } from 'rio-toasts';
import { RioTrackingStoreModule, TrackingService } from 'rio-tracking';
import { UploadStoreModule } from 'rio-upload-data-access';
import { rioUserInitializer } from '../initializers/rio-user-initializer';
import {
  SentryErrorHandlerService,
  initializeSentry,
} from '../initializers/sentry-initializer';
import { TioRewriteStoreModule } from '../initializers/tio-rewrite-store-module';
import { TioRewriteTranslateModule } from '../initializers/tio-rewrite-translate.module';
import { APIErrorHandlerInterceptor } from '../interceptors/api-error-handler.interceptor';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(TioRewriteStoreModule),
    importProvidersFrom(UserStoreModule),
    importProvidersFrom(SystemStoreModule),
    importProvidersFrom(OrganizationsStoreModule),
    importProvidersFrom(TioRewriteTranslateModule),
    importProvidersFrom(KeycloakAngularModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(LocalStorageStoreModule),
    importProvidersFrom(ToastStoreModule),
    importProvidersFrom(CategoriesStoreModule),
    importProvidersFrom(PromptsStoreModule),
    importProvidersFrom(PromptChainsStoreModule),
    importProvidersFrom(RouterStoreModule),
    importProvidersFrom(RioTrackingStoreModule),
    importProvidersFrom(UploadStoreModule),
    provideHotToastConfig(),
    provideHttpClient(),
    provideAppInitializer(() => {
      const initializerFn = initializeSentry();
      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    provideAppInitializer(() => {
      const initializerFn = rioUserInitializer(
        inject(KeycloakService),
        inject(Store),
        inject(OrganizationsService),
        inject(UserService),
        inject(TrackingService),
        inject(HttpClient),
      );
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = configProvider(inject(ConfigService));
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: API_PREFIX,
      useValue: '/api/v1',
    },
  ],
};
