import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { Injectable, NgModule, inject } from '@angular/core';
import { API_PREFIX, RESOURCES } from 'rio-core';
import { StackedToastService } from 'rio-toasts';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable()
export class APIErrorHandlerInterceptor implements HttpInterceptor {
  stackedToastService: StackedToastService = inject(StackedToastService);
  API_PREFIX = inject(API_PREFIX);

  requests = new Map([
    [RESOURCES.REWRITE.upload('organizationId'), 'Errors.Upload File'],
  ]);

  ignoredUrls = ['/transcription/', '/transcribe'];

  intercept(
    httpRequest: HttpRequest<HttpErrorResponse>,
    next: HttpHandler,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        const url = this.getUrl(error.url as string);

        switch (error.status) {
          case 0:
            this.stackedToastService.showToast('Errors.Network');
            break;

          case 504:
            this.stackedToastService.showToast('Errors.Timeout');
            break;

          default: {
            const normalizeUrl = this.normalizeUrl(url);

            if (
              this.ignoredUrls.some(ignoredUrl =>
                normalizeUrl.includes(ignoredUrl),
              )
            ) {
              break; // ignored urls might have their own error handling
            }

            if (this.requests.has(normalizeUrl)) {
              const errorMessage = this.requests.get(
                this.normalizeUrl(normalizeUrl),
              );
              this.stackedToastService.showToast(errorMessage);
              break;
            }

            if (error?.error?.message || error?.error?.detail) {
              const toastText = error?.error?.message || error?.error?.detail;
              this.stackedToastService.showToast(toastText);
              break;
            }

            this.stackedToastService.showToast('Errors.Generic');
            break;
          }
        }

        return throwError(() => error);
      }),
    );
  }

  getUrl(errorUrl: string): string {
    return errorUrl.split(`${this.API_PREFIX}`)[1];
  }

  private normalizeUrl(url: string): string {
    const regexExp =
      /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\//;
    return url?.replace(regexExp, '/organizationId/');
  }
}

@NgModule({
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIErrorHandlerInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class MockModule {}
